/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

:root {
  --hex-width: 30px;
  --hex-color: rgb(200, 200, 200);
  --hex-color-core: rgb(255, 155, 0);
}

@font-face {
  font-family: "NYT-Karnak";
  src: local("NYTKarnak-Medium"), url("./fonts/karnak-normal-500.woff2") format("woff2");
}

@font-face {
  font-family: "NYT-Franklin";
  src: url("./fonts/franklin-normal-500.woff2") format("woff2");
}

@font-face {
  font-family: "NYT-Cheltenham";
  src: url("./fonts/cheltenham-normal-300.woff2") format("woff2");
}

.game {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: max(10%, 100px) max(5%, 40px) 1fr;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  .game {
    grid-template-columns: 100%;
    grid-template-rows: max(10%, 100px) max(5%, 40px) max(5%, 40px) 1fr 1fr;
  }
}

.game>.title {
  margin: 24px 0;
  /* justify-content: center;
  align-items: center; */

  /* grid-column: span 2; */
  grid-column: 1/-1;
  vertical-align: baseline;
}

.game>.title {
  margin: 12px;
}

.game>.title>h3 {
  font-family: "NYT-Karnak", "Helvetica";
  font-size: 37px;
  font-weight: bold;
  margin: 0;
  /* width: 100%; */
}

.game>.title>span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 4px 0 -10px;

  font-family: "NYT-Franklin";
}

.menus-bar {
  /* grid-column: span 2; */
  border-top: 1px solid var(--hex-color);
  border-bottom: 1px solid var(--hex-color);
  padding: 0 5px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: middle;
}

.menu-item {
  height: 100%;
  /* padding: 0 5px 10px; */
  padding: 10px 10px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  /* padding: 2px 5px; */
  cursor: pointer;
  /* vertical-align: baseline; */
}

.menu-item:hover {
  background-color: rgb(255, 164, 246);
}

.menu-clock {
  border-top: 1px solid var(--hex-color);
  border-bottom: 1px solid var(--hex-color);
  height: 100%;
  width: 100%;
  /* padding: 0 5px 10px; */
  padding: 1px 1px;
  /* align-self: flex-start; */
  /* display: inline-block; */
  /* position: relative; */
  box-sizing: border-box;
  font-size: 2em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* padding: 2px 5px; */
  /* vertical-align: baseline; */
}

.menu-pause {
  height: 100%;
  /* padding: 0 5px 10px; */
  padding: 1px 1px;
  align-self: flex-start;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-size: 2em;
  font-weight: 700;
  margin-right: auto;
}

.letter-box {
  display: grid;
  grid-template-columns: 100%;
}

.letter-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
  font-family: "NYT-Franklin";
  font-weight: 700;
  font-size: 2em;
}

.letter-bar>.not-in {
  color: var(--hex-color);
}

.letter-bar>.core {
  color: var(--hex-color-core);
}

.letter-bar::after {
  content: '';
  display: block;
  margin-left: 2px;
  top: 0;
  width: 2px;
  height: 1.1em;
  background-color: var(--hex-color-core);
  animation: 1000ms blink step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0%;
  }
}

.hex-box {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 40px auto;
}

.letter-hex:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: var(--hex-width) solid var(--hex-color);
  border-left: calc(var(--hex-width) * 1.7321) solid transparent;
  border-right: calc(var(--hex-width) * 1.7321) solid transparent;
  position: absolute;
  top: calc(-1 * var(--hex-width));
}

.letter-hex {
  float: left;
  margin-top: var(--hex-width);
  margin-bottom: calc(0.1 * var(--hex-width));
  margin-left: calc(0.1 * var(--hex-width));
  width: calc(3.4667 * var(--hex-width));
  height: calc(2 * var(--hex-width));
  background-color: var(--hex-color);
  position: relative;

  /* text-align: center;
  vertical-align: middle;
  line-height: calc(2 * var(--hex-width)); */

  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "NYT-Franklin";
  font-weight: 700;
  font-size: 2em;
  user-select: none;

  transition: all 100ms;
}

.letter-hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: calc(-1 * var(--hex-width));
  border-top: var(--hex-width) solid var(--hex-color);
  border-left: calc(var(--hex-width) * 1.7333) solid transparent;
  border-right: calc(var(--hex-width) * 1.7333) solid transparent;
}

.hex-row {
  clear: left;
}

.hex-row.odd {
  margin-left: calc(var(--hex-width) * 1.767);
  background-color: #61dafb;
}

/* .hex-row.odd>.letter-hex {
  margin-left: calc(var(--hex-width) * 1.7321);
} */
/* 
.letter-hex:nth-of-type(1) {} */

.letter-hex.core {
  background-color: var(--hex-color-core);
}

.letter-hex.core::before {
  border-bottom: var(--hex-width) solid var(--hex-color-core);
}

.letter-hex.core::after {
  border-top: var(--hex-width) solid var(--hex-color-core);
}

.letter-hex.pressed {
  transform: scale(0.8);
}

.game-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-buttons>button {
  padding: 20px;
  margin: 0 10px;
  background-color: white;
  border: 1px solid var(--hex-color);

  border-radius: 2.5em;

  user-select: none;
  cursor: pointer;
}

.game-buttons>button:active {
  background-color: var(--hex-color);
}

@media only screen and (max-width: 700px) {
  :root {
    --hex-width: 4vw;
  }

}

.score-root {
  font-family: "NYT-Franklin";
  margin: 0 10px;
}


.score-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px 6px 0;
}

.score-name {
  font-weight: 700;
  min-width: 6em;
  display: flex;
}

.score-graphics {
  margin-left: 12px;
  flex-grow: 1;
  position: relative;
  min-width: calc(100% - 12px - 102px);
  display: flex;
  align-items: center;
}

.score-line {
  position: absolute;
  height: 2px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--hex-color);
}

.score-dots {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.score-dot {
  display: block;
  width: 0;
  position: relative;
}

.score-dot::after {
  content: '';
  display: block;
  position: absolute;
  left: -4.5px;
  top: -4.5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--hex-color);
}

.score-dot.square::after {
  border-radius: 0;
}

.score-dot.completed::after {
  background-color: var(--hex-color-core);
}

.score-marker {
  width: 1.875em;
  height: 1.875em;

  position: absolute;
  left: 0%;
  transition: left 200ms ease;
  transform: translate(-50%, 0);
}

.score-score {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: black;
  background: var(--hex-color-core);
  font-size: 0.75em;
  font-weight: 500;
  transition: border-radius 400ms ease-in;
}

.score-score.queen {
  border-radius: 0%;
}

.wordlist-root {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  height: 1fr;
  overflow: hidden;
  /* overflow-x: auto;
  overflow-y: hidden; */
  z-index: 0;
  scroll-margin: 0;
  scroll-behavior: smooth;
  overscroll-behavior: contain;

  border: 1px solid var(--hex-color);
  border-radius: .5em;
}

.wordlist-summary {
  height: 45px;
  line-height: 45px;
  padding: 0 25px;
}

.wordlist-list {
  padding: 0 0 24px;
  width: 100%;
  max-height: none !important;
  margin-left: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  align-content: flex-start;
  height: calc(100% - 38px);
  list-style: none;
}

.wordlist-word {
  text-transform: capitalize;

  width: calc(50% - 25px);
  margin-right: 20px;
  padding: 4px 0 4px 1px;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--hex-color);
}

.wordlist-word.pangram {
  font-weight: 600;
}

.overlay-items {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.619);
}

.overlay-items.closed {
  display: none;
}

.overlay-items.open {
  display: block;
}

.overlay-items>.modal {
  height: 400px;
  width: 400px;
  background: rgb(255, 255, 255);
  border: 2px solid black;
  margin: 300px auto;
  padding: 8px;
  box-sizing: border-box;
}

.modal>.title {
  font-family: "NYT-Karnak";
  font-size: 2em;
  font-weight: 600;
}

.stats-block {
  display: grid;
  grid-template-columns: 50% 50%;
}

.stats-block:nth-child(2) {
  font-weight: 600;
}

.footer {
  background-color: var(--hex-color);
  padding: 4px;
  font-family: "NYT-Franklin";
  font-size: .75rem;
}

.footer>span {
  font-family: "NYT-Karnak";
}